<template>
  <div>
    <div v-if="totalNum > 0">
      <div class="content" v-for="(item, index) in list" :key="index">
        <div class="content-user">
          <div class="content-user-info">
            <div class="head">
              <Uimage :src="item.headPhoto" />
            </div>
            <div class="uName">
              <span>{{ item.memberUsername ? item.memberUsername : item.memberPhone }}</span>
            </div>
          </div>
        </div>
        <div class="content-formulation">
          <div class="rate">
            <el-rate disabled :value="item.commodity"></el-rate>
          </div>
          <div class="text">
            <span>{{ item.content }} </span>
            <div class="enclosure">
              <!--<div class="enclosure-item video activate">
            <img src="../../assets/details/tmp/evaluate_thumbnail_001.png" />
          </div>
          <div class="enclosure-item ">
            <img src="../../assets/details/tmp/evaluate_thumbnail_002.png" />
          </div>
          <div class="enclosure-item ">
            <img src="../../assets/details/tmp/evaluate_thumbnail_003.png" />
          </div>
          <div class="enclosure-item ">
            <img src="../../assets/details/tmp/evaluate_thumbnail_004.png" />
          </div>-->
              <div class="enclosure-enlarge">
                <!--<div class="enclosure-enlarge-img">
              <img src="../../assets/details/tmp/evaluate_thumbnail_000.jpg" />
            </div>-->
                <div class="enclosure-enlarge-title">{{ item.skuName ? item.skuName : item.spuName }} {{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="text" v-if="item.reply != null">
            <span style="color: #ff561c">店铺回复：</span>
            <span style="color: #ff561c">{{ item.reply }}</span>
            <div class="enclosure">
              <div class="enclosure-enlarge">
                <div class="enclosure-enlarge-title">
                  {{ item.replyDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="totalNum == 0">
      <div class="tishi">{{ tishi }}</div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
import { getCommentSpuIdCount } from "@/api/store";
export default {
  inject: ["bus"],
  data() {
    return {
      list: [
        {
          userPig: "../../assets/details/evaluate_grade.png",
          userName: "222",
          commodity: 5,
          content: "515",
          createTime: "2020-02-02 11:11:11",
          spuName: "sddjjnvnvfjf",
          level: "5",
        },
        {
          userPig: "../../assets/details/evaluate_grade.png",
          userName: "11",
          commodity: 3,
          content: "5155",
          createTime: "2020-02-02 11:11:11",
          spuName: "dndfkdfndjn",
          level: "5",
        },
      ],
      isLoading: true,
      currentPage: 1,
      totalNum: 1,
      mes: "",
      tishi: this.$t('common.Its_empty'),
      spuId: 0,
      typeActive: "all",
    };
  },
  created() {
    // this.bus.on("attrSelect", (msg) => {
    //   this.spuId = msg;
    //   this.getorder();
    // });
  },
  methods: {
    getCommentCount() {
      this.getorder();
    },
    getorder() {
      get("f/api/frontcomment/getproductcommentlist/" + this.spuId + "/" + this.typeActive + "?pageNum=" + this.currentPage + "&pageSize=10").then((res) => {
        this.list = res.data.data.list;
        this.$parent.totalNum = this.totalNum = res.data.data.total;
      });
    },
  },
};
</script>
<style lang="scss">
.rate {
  > .el-rate {
    > .el-rate__item {
      > .el-rate__icon {
        margin-right: 0px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  margin-top: 27px;

  .content-aa {
    width: 100%;
  }

  &-user {
    display: inline-block;
    flex: 155px;

    &-info {
      width: 100%;
      display: flex;
      flex-direction: row;

      .head {
        display: inline-block;
        background: transparent;
        //background-image: url("../../assets/details/evaluate_head_sculpture.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 50%;
        border: 1px solid #fffffd;
        box-shadow: -2px -2px 5px rgb(0 0 0 / 25%), 2px -2px 5px rgb(0 0 0 / 25%), 2px 2px 5px rgb(0 0 0 / 25%), -2px 2px 5px rgb(0 0 0 / 25%);
        width: 34px;
        height: 34px;
        margin: 0px 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .uName {
        display: inline-block;
        padding-left: 8px;
        width: calc(100% - 42px);
        height: 34px;
        line-height: 34px;
        overflow: hidden;
      }
    }

    &-grade {
      width: 48px;
      height: 19px;
      margin-top: 10px;

      .uGrade {
        width: 48px;
        height: 19px;

        > text {
          font-size: 0.7em;
          fill: #fff;
        }
      }
    }
  }

  &-formulation {
    display: inline-block;
    flex: 100;

    .rate {
      height: 34px;
    }

    .text {
      max-width: 100%;

      .enclosure {
        width: 100%;

        &-item {
          display: inline-block;
          width: 53px;
          height: 53px;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0px;
          }

          > img {
            width: 100%;
            height: 100%;
          }

          &.activate {
            position: relative;
            margin-top: 19px;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: transparent;
              background-image: url("../../assets/details/evaluate_enclosure.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              z-index: 1;
            }

            &.video {
              position: relative;

              &:after {
                content: "";
                position: absolute;
                width: 17px;
                height: 17px;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
                background: transparent;
                background-image: url("../../assets/details/evaluate_enclosure_video.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                z-index: 1;
              }
            }
          }
        }

        &-enlarge {
          &-img {
            height: 271px;
            width: 277px;

            > img {
              width: 100%;
              height: 100%;
            }
          }

          &-title {
            margin: 8px 0px;
            color: #959595;
          }
        }
      }
    }
  }
}
.tishi {
  width: 100%;
  text-align: center;
  // margin-top: 150px;
  height: 175px;
  line-height: 175px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
</style>
