<template>
  <div class="evaluate">
    <div class="evaluate-top">
      <div class="evaluate-top-item evaluate-rate">
        <div class="evaluate-rate-content">
          <span class="evaluate-rate-content-title">{{ $t('common.Degree_of_praise') }}</span>
          <span class="evaluate-rate-content-rate">{{
            commodityAvg ? Number(commodityAvg).toFixed(1) : 0
          }}</span>
          <div class="rate">
            <el-rate
              disabled
              :value="commodityAvg ? Number(commodityAvg).toFixed(1) : 0"
            ></el-rate>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="evaluate-top-item evaluate-label">
         <div class="evaluate-label-item">
          <span>是正品(165)</span>
        </div>
        <div class="evaluate-label-item">
          <span>有定级证书(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>有生产许可证(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>一级标准其他(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>性价比高(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>会回购(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>包装严谨(96)</span>
        </div>
        <div class="evaluate-label-item">
          <span>安全(96)</span>
        </div> 


      </div>
    </div>
    -->
    <div class="evaluate-bottom">
      <div class="evaluate-type">
        <div class="evaluate-type-content evaluate-type-content--01">
          <ul class="evaluate-type-content-m">
            <li
              :class="typeActive == 'all' ? active : ''"
              @click="getorder('all')"
            >
              <span>{{ $t('common.Total_evaluation') }}({{ commentCount ? commentCount : 0 }})</span>
            </li>
            <li
              :class="typeActive == 'hp' ? active : ''"
              @click="getorder('hp')"
            >
              <span>{{ $t('common.Good_reputation') }}({{ commodityHp ? commodityHp : 0 }})</span>
            </li>
            <li
              :class="typeActive == 'zp' ? active : ''"
              @click="getorder('zp')"
            >
              <span>{{ $t('common.Medium_review') }}({{ commodityZp ? commodityZp : 0 }})</span>
            </li>
            <li
              :class="typeActive == 'cp' ? active : ''"
              @click="getorder('cp')"
            >
              <span>{{ $t('common.Negative_comment') }}({{ commodityCp ? commodityCp : 0 }})</span>
            </li>
          </ul>
        </div>

        <!--<div class="evaluate-type-content">
          <div class="evaluate-type-content-s">
            <div class="only-wares">
              <label for="__c_001">
                <input type="checkbox" id="__c_001" />
                <span>只查看当前商品</span>
              </label>
            </div>
            <div class="select-sort">
              <span>推荐排序</span>
            </div>
          </div>

        </div>-->
      </div>

      <div class="evaluate-content">
        <div class="evaluate-content-item">
          <evaluate-content ref="content"></evaluate-content>
        </div>
      </div>
      <div class="evaluate-page">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :total="totalNum"
          :page-size="10"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import evaluate_content from './details-evaluate-content.vue';
export default {
  components: {
    'evaluate-content': evaluate_content,
  },
  data() {
    return {
      commentCount: 0,
      commodityAvg: 0,
      commodityHp: 0,
      commodityZp: 0,
      commodityCp: 0,
      isLoading: true,
      currentPage: 1,
      mes: '',
      spuId: 0,
      totalNum: 0,
      typeActive: 'all',
      active: 'activate',
    };
  },
  methods: {
    getCommentCount() {
      setTimeout(() => {
        this.$refs.content.spuId = this.spuId;
        this.$refs.content.getCommentCount();
      }, 10);
    },
    getorder(typeActive) {
      this.$refs.content.typeActive = this.typeActive = typeActive;
      this.$refs.content.currentPage = this.currentPage = 1;
      this.$refs.content.getorder();
    },
    pagechange(p) {
      this.$refs.content.currentPage = this.currentPage = p;
      this.$refs.content.getorder();
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate {
  width: 100%;
  margin-top: 20px;

  &-top {
    display: flex;
    flex-direction: row;

    &-item {
      display: inline-block;

      &.evaluate-rate {
        width: 100px;
        height: 100px;
        background: transparent;

        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;

        .evaluate-rate-content {
          display: flex;
          flex-direction: column;

          text-align: center;
          width: 100px;
          height: 75px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;

          &-rate,
          &-title {
            display: inline-block;
          }

          &-rate {
            font-size: 23px;
            font-weight: 700;
            color: #cc1414;
          }
        }
      }

      &.evaluate-label {
        width: calc(100% - 78px);
        margin-left: 16px;

        .evaluate-label-item {
          font-size: 12px;
          display: inline-block;
          padding: 7px 15px;
          border: 1px solid #eaeaea;
          border-radius: 6px;
          margin-right: 15px;

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }

  &-bottom {
    margin-top: 38px;
    width: 100%;

    .evaluate-type {
      display: flex;
      flex-direction: row;
      height: 35px;
      background: 100%;
      background-image: url('../../assets/details/evaluate_type.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 0px 15px;

      .evaluate-type-content {
        display: inline-block;
        line-height: 35px;

        &.evaluate-type-content--01 {
          overflow: hidden;
        }

        &-m {
          list-style: none;
          margin: 0px;
          padding: 0px;

          > li {
            display: inline-block;
            margin-left: 15px;
            cursor: pointer;

            &:hover,
            &.activate {
              color: #cd2121;
            }

            &:first-child {
              margin-left: 0px;
            }
          }
        }

        &-s {
          cursor: default;
          width: 235px;

          .only-wares {
            display: inline-block;

            input[type='checkbox'] {
              border: 0px;
              background: transparent;
              background-image: url('../../assets/details/evaluate_only_wares.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              margin: 0px;
              margin-right: 3px;
              display: inline-block;

              &:focus {
                outline: none;
              }
            }
          }

          .select-sort {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }

    .evaluate-content {
      width: 100%;

      &-item {
        width: 100%;
      }
    }

    .evaluate-page {
      display: flex;
      flex-direction: row-reverse;
      text-align: right;
    }
  }
}
</style>
