<template>
  <div class="crumbs-content w-1920">
    <div class="crumbs w-1200">
      <ul class="crumbs-ul">
        <li class="crumbs-li crumbs-item">
          <p class="crumbs-item-text">{{ $t('common.Enterprise_shop') }}</p>
        </li>
        <li class="crumbs-li crumbs-item" @click="handleMenuClickHome()">
          <p class="crumbs-item-text">{{ form && form.name }}</p>
        </li>
        <li class="crumbs-li crumbs-item">
          <p class="crumbs-item-text">{{ $t('common.Standard_gas') }}</p>
        </li>
        <li class="crumbs-li crumbs-item">
          <p class="crumbs-item-text">{{ $t('common.shortmessage-016') }}</p>
        </li>
      </ul>
      <div class="crumbs-other">
        <div class="other-item storeName" @click="handleMenuClickHome()">
          <span>{{ form && form.name }}</span>
        </div>
        <div class="other-item customer">
          <span>{{ customer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store_Default from '../store/mixins-default';
import {
  selShopStoreInfo,
  insertCollectStore,
  cancelstore,
  isfollowstore,
} from '@/api/store';
export default {
  mixins: [store_Default],
  data() {
    return {
      crumbs: [
        this.$t('common.Enterprise_shop'),
        this.$t('common.shortmessage-017'),
        this.$t('common.Standard_gas'),
        this.$t('common.shortmessage-016'),
      ],
      customer: this.$t('foot.Live_Support'),
      form: {},
      spuId: '',
    };
  },
  created() {
    this.spuId = this.$route.params.spu;
    selShopStoreInfo(this.storeId).then((data) => {
      if (data) {
        this.form = data;
      }
    });
  },
  methods: {
    handleMenuClickHome() {
      this.$router.push({
        name: 'store_index',
        params: {
          sid: this.storeId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.crumbs {
  position: relative;
  height: 36px;
  width: 1271px;
  margin: auto;
  padding: 0px;

  &-content {
    width: 100%;
    background: #ffffff;
  }

  &-other {
    position: absolute;
    display: inline-block;
    right: 0px;
    top: 0px;
    font-size: 14px;

    .other-item {
      display: inline;
      height: 100%;
      line-height: 36px;
      padding: 0px 10px;
      color: #343434;
    }

    .storeName {
      font-weight: bold;
      color: #4298c7;
    }

    .customer,
    .follow {
      position: relative;

      > span {
        margin-left: 22px;
      }

      &:before {
        content: '';
        position: absolute;
        background: transparent;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }

    .customer {
      &:before {
        width: 11px;
        height: 11px;
        background-image: url('../../assets/details/contact_customer_service.png');
      }
    }

    .follow {
      &:before {
        width: 12px;
        height: 12px;
        background-image: url('../../assets/details/follow_01.png');
      }

      &.active {
        > span {
          &:before {
            content: '已';
          }
        }
      }
    }
  }

  .crumbs-ul {
    list-style: none;
    height: 100%;
    margin: 0px 0px;
    padding: 0px;
    // margin-left: 362.00px;
    line-height: 27px;

    .crumbs-li {
      display: inline-block;
      height: auto;
      width: auto;
    }
  }

  .crumbs-item {
    color: #272727;

    &:last-of-type {
      .crumbs-item-text {
        &:after {
          content: '';
        }
      }
    }

    .crumbs-item-text {
      position: relative;
      margin: 0px;
      padding: 4.5px 16px;
      font-size: 12px;

      &:before {
      }

      &:after {
        content: '>';
        position: absolute;
        display: inline-block;
        /* width: 0.04947917rem; */
        /* height: 0.08854167rem; */
        right: -6px;
        /* top: 0; */
        /* bottom: 0; */
        /* margin: auto; */
      }
    }
  }
}
</style>
