<template>
  <div class="block-width details-wares w-1920">
    <div class="block-wares w-1200">
      <div class="wares">
        <div class="wares-image">
          <div class="enlarge">
            <div class="enlarge-image">
              <div class="left">
                <!-- 大图 -->
                <Uimage class="leftImg" :src="activateImage" alt="" />
                <div v-show="topShow" class="top" :style="topStyle"></div>
                <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div>
              </div>
              <!-- 鼠标放上去右侧放大镜 -->
              <div v-show="rShow" class="right">
                <Uimage :style="r_img" class="rightImg" :src="activateImage" alt="" />
              </div>
            </div>
            <div class="enlarge-many">
              <!-- 左边滑块 -->
              <div class="enlarge-many-btn enlarge-many-upper" @click="swiper_left">
                <img src="../../assets/details/wares_image_upper.png" />
              </div>
              <div class="enlarge-many-image">
                <!-- 小图列表 -->
                <ul class="enlarge-many-image-block">
                  <li class="enlarge-many-image-item" v-for="(img, index) in imgList" :key="index" :class="{ activate: activateIndex == index }" @click="activate(img, index)">
                    <div class="enlarge-many-image-item-block">
                      <Uimage :src="img.url" />
                    </div>
                  </li>
                </ul>
              </div>
              <!-- 右边滑块 -->
              <div class="enlarge-many-btn enlarge-many-lower" @click="swiper_right()">
                <img src="../../assets/details/wares_image_lower.png" />
              </div>
            </div>
          </div>
          <div class="special">
            <div class="special-item" @click="handleSubscribe">
              <div class="special-item-text">
                <div class="guanzhu" v-if="subscribe">
                  <img src="../../assets/details/follow_02.png" />
                </div>
                <div class="guanzhu" v-else>
                  <img src="../../assets/store/subscribe.png" />
                </div>
                <span>{{ subscribe ? "已关注" : "关注" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="wares-introduce">
          <div class="introduce-title">
            <div class="introduce-title-sign">
              <div class="introduce-title-sign-item">
                <span>{{ $t('common.Target_operation') }} </span>
              </div>
            </div>
            <div class="introduce-title-wares">
              <p>{{ spu.name }}</p>
            </div>
          </div>
          <div class="introduce-price">
            <div class="introduce-price-text" v-if="doJf">
              <div class="introduce-price-text-title">
                <span class="spu-text">{{ $t('common.Required_integral') }}</span>
              </div>
              <div class="introduce-price-text-text">
                <span class="money"> {{ realPrice2 }} {{ $t('common.points') }} </span>
              </div>
            </div>
            <div class="introduce-price-text">
              <div class="introduce-price-text-title">
                <span class="spu-text">{{ $t('common.Commodity_price') }}</span>
              </div>
              <div class="introduce-price-text-text">
                <span class="money">
                  <span class="money-integer">{{ realPrice }}</span>
                </span>
              </div>
            </div>
            <div class="introduce-price-text" v-if="couponList.length > 0 && !doJf">
              <div class="introduce-price-text">
                <div class="introduce-price-text-title">
                  <span class="spu-text">{{ $t('common.coupon') }}</span>
                </div>
                <div class="introduce-price-text-text">
                  <div class="full-reduction">
                    <div class="full-reduction-item" v-for="(item, index) in couponList" :key="index" @click="receiveCoupon(item.couponId)">
                      <span>满{{ item.orderAmount }}减{{ item.discountAmount }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="introduce-mode mode-select">
            <div>
              <!-- 这里原来有个自定义组分 -->
              <dl class="attr-wrapper clearfix" v-for="(root, rIndex) in attrData" :key="rIndex">
                <dt class="attr-type">
                  <span>{{ root.name }}</span>
                  <!-- <el-popover v-if="root.code == 'gasType_spec'" placement="right" width="300" trigger="hover">
                    <div style="display: inline-flex; flex-direction: column; flex-wrap: nowrap; align-content: flex-start">
                      <h5 style="margin: 6px 0px">气瓶规格编号说明</h5>
                      <div style="display: inline-grid; grid-template-columns: 90px 1fr; grid-template-rows: 1fr; margin-bottom: 6px">
                        <span>代码</span>
                        <span>名称</span>
                      </div>
                      <div v-for="(item, index) in bottleTypeList" :key="index" style="display: inline-grid; grid-template-columns: 90px 1fr; grid-template-rows: 1fr; margin-bottom: 6px">
                        <span>{{ item.dictValue }}</span>
                        <span>{{ item.dictLabel }}</span>
                      </div>
                    </div>
                    <span slot="reference">
                      <i class="el-icon-question"></i>
                    </span>
                  </el-popover> -->
                </dt>
                <dd>
                  <ul class="attr-list clearfix">
                    <template v-for="(data, dataindex) in selectTree[rIndex]">
                      <li class="attr-text1 attr-text" :class="{ 'attr-select': selectKeys[rIndex] == getKeys(data), dark: disableAttr.includes(getKeys(data)) }" :key="dataindex" @click="attrSelect(rIndex, data, root.code)">
                        <!-- <span v-if="disableAttr.indexOf(item.prodAttrId + ':' + data.prodAttrDataId) != -1">(禁用了)</span> -->
                        <a href="javascript:void(0);">
                          <span v-html="filterAttrValue(data, rIndex, root)"></span>
                        </a>
                      </li>
                    </template>
                  </ul>
                  <ul v-if="root.code == 'gasType_spec'"  >
                    <div style="font-size: 10px;color: #6c6c6c;display: grid; grid-template-columns: repeat(5, auto);">
                      <div>A:普通右旋国产阀</div>
                      <div>B:反丝左旋</div>
                      <div>C:右旋进口瓶阀</div>
                      <div>D:钢瓶捷仪阀</div>
                      <div>E:不锈钢阀+涂层(腐蚀性气体)</div>
                      <div>G:镀镍K</div>
                      <div>V:VOC用瓶</div>
                      <div>F:研磨</div>
                      <div>I:铝合金气瓶+不锈钢阀</div>
                      <div>H:钢瓶不锈钢瓶阀</div>
                    </div>
                  </ul>
                </dd>
              </dl>
              <dl class="attr-wrapper clearfix" v-if="isHanLiang">
                <dt class="attr-type">{{ $t('common.Confirmed_content') }}</dt>
                <dd>
                  <ul class="attr-list clearfix">
                    <li class="confirm-wrapper">
                      <el-input v-model="hanliang" style="width: 100px"></el-input>
                    </li>
                    <li class="attr-text1 attr-unit" v-for="(unit, index) in unitlist" :key="index" :class="{ bian: index == TabIndex }" @click="changeunit(index, unit)">
                      <a href="javascript:void(0);">
                        <span>
                          <span v-if="index == 0">
                            10
                            <sup>-6</sup>
                          </span>
                          {{ unit }}
                        </span>
                      </a>
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl class="attr-wrapper clearfix">
                <dt class="attr-type">{{ $t('common.quantity') }}</dt>
                <dd>
                  <div class="counter">
                    <div class="counter-btn counter-reduce" @click="handleCounterClick(-1)">
                      <span>-</span>
                    </div>
                    <div class="counter-number">
                      <input v-model="counter" />
                    </div>
                    <div class="counter-btn counter-plus" @click="handleCounterClick(+1)">
                      <span>+</span>
                    </div>
                    <div class="counter-other">
                      <span> 件</span>
                      <span> （库存{{ selectFLag ? selectSku.inventory : spu.inventory }}件）</span>
                    </div>
                  </div>
                </dd>
              </dl>
              <div class="bianhao" v-if="selectSku.skuNum != ''">
                <div class="bianhao_title">{{ $t('common.Mark_number') }}</div>
                <div class="bianhao_number">{{ addattrValueList.length > 0 && spuNum != "" ? spuNum : selectSku.skuNum }}</div>
              </div>
              <ul class="attrvalue" v-if="addattrValueList != ''">
                <li class="addattrvalue">已选择组分：{{ lockValue }}</li>
                <li class="addattrvalue" v-for="(data, index) in addattrValueList" :key="index">
                  <div style="display: inline-grid; margin: 5px 10px; gap: 20px; grid-template-columns: 120px 100px 1fr; align-items: center; justify-items: end; width: 80%">
                    <span>{{ data.attrCode }}</span>
                    <span>{{ data.attrValueStr }}&nbsp;{{ data.danwei }}</span>
                    <div style="width: 100%; height: 100%">
                      <span>{{ data.attrValue }}</span>
                    </div>
                  </div>
                  <button class="del_btn" @click="delzufen(index)">
                    <i class="el-icon-delete"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div class="introduce-mode-item" style="margin-top: 30px" v-if="doJf">
              <button class="button-order" @click="buyjifen()">
                <span>{{ $t('button.Point_exchange') }}</span>
              </button>
              <button class="addbutton" v-if="duozufen" @click="addzufen()">
                <span>{{ $t('common.shortmessage-018') }}</span>
              </button>
            </div>
            <div class="introduce-mode-item" style="margin-top: 30px" v-else>
              <button class="button-order" @click="doBuy()">
                <span>{{ $t('common.Buy_now') }}</span>
              </button>
              <button class="button-cart" @click="handleSettlementCart()">
                <span>{{ $t('button.Add_to_cart') }}</span>
              </button>
              <button class="addbutton" v-if="duozufen" @click="addzufen()">
                <span>{{ $t('common.shortmessage-018') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import wares from "./details-wares.mixins";
import "swiper/swiper.min.css";

export default {
  mixins: [wares],
  inject: ["bus"],
  data() {
    return {
      topStyle: { transform: "" },
      r_img: { transform: "" },
    };
  },
  watch: {
    counter(now, old) {
      //监听数量的输入，如果不是纯数字使用上一个文本
      if (/[^0-9]+/.test(now)) {
        this.counter = Number(old);
      } else {
        this.counter = Number(now);
      }
    },
    hanliang(newVal, oldVal) {
      if (newVal == undefined || newVal == null || newVal == "") {
        this.hanliang = 0;
      }
      if (/[^\d\.]/.test(newVal)) {
        this.hanliang = oldVal;
        return;
      }
      if (typeof newVal == "string") {
        if (newVal.startsWith("0") && !newVal.startsWith("0.")) {
          this.hanliang = parseFloat(newVal);
          return;
        }
        try {
          newVal = parseFloat(newVal);
        } catch (e) {
          this.hanliang = oldVal;
        }
      }
    },
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      // 层罩及放大空间的显示
      this.topShow = true;
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 145) {
        topX = 145;
      }
      if (topY > 145) {
        topY = 145;
      }
      // this.$refs.topShow.style = `translate(${topX}px,${topY}px)`;
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.topShow = false;
      this.rShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bianhao {
  font-size: 12px;
  color: #6c6c6c;
  display: flex;

  .bianhao_number {
    margin-left: 20px;
  }
}

.attrvalue {
  border: 1px solid red;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  .del_btn {
    border: none;
  }
}

.addattrvalue {
  width: 100%;
  font-size: 12px;
  color: #6c6c6c;
}

.addbutton {
  margin-left: 20px;
}

.special-item-text {
  display: flex;

  span {
    margin-left: 5px;
    padding-bottom: 2px;
  }
}

.guanzhu {
  display: flex;
  align-items: center;
}

.set-btn {
  padding: 10px 20px;
  font-size: 12px;
}

.confirm-wrapper .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 120px;
  border-radius: 0;
}

.confirm-wrapper .el-input.is-active .el-input__inner,
.confirm-wrapper .el-input__inner:focus {
  border-color: #1950ff;
  outline: 0;
}
</style>

<style scoped>
ul,
li,
dd,
dt,
dl {
  list-style: none;
  padding: 0;
  margin: 0;
}

.introduce-mode-item-title,
.counter-other {
  font-size: 12px;
  color: #6c6c6c;
}

.mode-select {
  /* border: 2px solid #1950ff; */
}

.clearfix::after {
  visibility: hidden;
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.attr-wrapper > dd {
  overflow: hidden;
  margin-bottom: 10px;
}

.attr-type {
  float: left;
  margin-right: 5px;
  width: 65px;
  font-size: 12px;
  color: #6c6c6c;
  margin-top: 8px;
}
.attr-list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.attr-list > li {
  float: left;
  margin: 0 5px 10px 0;
  vertical-align: middle;
  line-height: 20px;
}

.attr-list .attr-select > a {
  color: #1950ff;
  border: 2px solid #1950ff;
  padding: 0 4px;
}

.attr-list-none > a {
  background: #cecece;
  color: #cecece;
  border: 2px solid #cecece;
}

.attr-text a:hover {
  border: 2px solid #1950ff;
  padding: 0 4px;
}

/* .attr-unit a:hover {
  border: 2px solid #1950ff;
  padding: 0 4px;
} */

.attr-text a {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  padding: 1px 5px;
  font-size: 12px;
  min-width: 32px;
  text-align: center;
  border: 1px solid #dcdcdc;
  height: 32px;
  line-height: 32px;
}

.bian {
  color: #1950ff;
  border: 2px solid #1950ff;
}

.attr-text1 a {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  padding: 1px 5px;
  font-size: 12px;
  min-width: 32px;
  text-align: center;
  border: 1px solid #dcdcdc;
  color: #3c3c3c;
  background: #fff;
  height: 32px;
  line-height: 32px;
}
/** 
.dark {
  display: none;
}*/
.dark {
  cursor: no-drop;
  pointer-events: none;
}
.dark a {
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  padding: 1px 5px;
  font-size: 12px;
  min-width: 32px;
  text-align: center;
  border: 1px solid #dcdcdc;
  color: #000;
  background: #cecece;
  height: 32px;
  line-height: 32px;
  cursor: default;
}

.spu-text {
  margin-right: 5px;
  width: 55px;
  font-size: 12px;
  color: #6c6c6c;
  display: inline-block;
}

.introduce-title-wares p {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
</style>

<style lang="scss" scoped>
.block-width {
  position: relative;
  width: 100%;

  &.details-wares {
    height: auto;
  }

  .block-wares {
    margin: auto;
    margin-top: 18px;
    background: #fff;
    padding: 26px 26px 30px 26px;
  }

  .wares {
    display: flex;
    flex-direction: row;
    margin-bottom: 25.7px;

    &-image {
      display: inline-block;
      width: 345px;
      height: auto;

      .enlarge {
        width: 100%;
        // height: 100%;

        &-image {
          width: 345px;
          height: 345px;
          cursor: move;

          /* 放大的图片，通过定位将左上角定位到(0,0) */
          .rightImg {
            display: inline-block;
            width: 800px;
            height: 800px;
            position: absolute;
            top: 0;
            left: 0;
          }

          /* 右边的区域图片放大空间 */
          .right {
            margin-left: 347px;
            width: 400px;
            height: 421px;
            position: relative;
            border: 1px solid #cecece;
            top: -345px;
            overflow: hidden;
            z-index: 999;
            border-left: none;
          }

          /* 一个最高层层罩 */
          .maskTop {
            width: 345px;
            height: 345px;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            cursor: move;
          }

          /* 层罩，通过定位将左上角定位到(0,0) */
          .top {
            width: 200px;
            height: 200px;
            background-color: #afaf2b;
            opacity: 0.4;
            position: absolute;
            top: 0;
            left: 0;
          }

          /* 原图的显示 */
          .leftImg {
            width: 345px;
            height: 345px;
            display: inline-block;
            border: 1px solid #cecece;
          }

          /* 原图的容器 */
          .left {
            width: 345px;
            height: 345px;
            float: left;
            position: relative;
          }
        }

        &-many {
          position: relative;
          height: 58px;
          margin-top: 18px;
          padding: 0px 2px;

          &-btn {
            height: 34px;
            width: 18px;
            display: inline-block;
            position: absolute;
            top: 0px;
            bottom: 0px;
            margin: auto;
            cursor: pointer;

            > img {
              width: 100%;
              height: 100%;
            }
          }

          &-upper {
            left: 0px;
          }

          &-lower {
            right: 0px;
          }

          &-image {
            padding: 0px 34px;
            width: calc(100% - 68px);
            height: 100%;
            overflow: hidden;

            &-block {
              list-style: none;
              padding: 0px;
              margin: 0px;
              width: 100%;
              height: 100%;
            }

            &-item {
              display: inline-block;
              height: 100%;
              width: 58px;
              margin-left: 6px;
              cursor: pointer;

              &.activate {
                position: relative;

                &:before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width: 100%;
                  height: 100%;
                  background: transparent;
                  background-image: url("../../assets/details/thumbnail.png");
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                }
              }

              &:first-of-type {
                margin-left: 0px;
              }

              &-block {
                > img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .special {
        margin-top: 10px;
        width: 100%;
        height: 22px;

        &-item {
          display: inline-block;
          cursor: pointer;
          margin-left: 20px;

          &.special-item-right {
            float: right;
          }

          &:first-of-type {
            margin-left: 0px;
          }

          &-text {
            position: relative;
            padding-left: 22px;

            > img {
              width: 20px;
              height: 20px;
              position: absolute;
              top: 0px;
              bottom: 0px;
              left: 0px;
              margin: auto;
            }
          }
        }
      }
    }

    &-introduce {
      margin-left: 15px;
      width: 637px;

      .introduce-title {
        width: 100%;
        max-height: 44px;
        display: flex;
        flex-direction: row;

        &-sign {
          display: inline-block;

          &-item {
            // width: 70px;
            padding: 0px 6px;
            background: #1751fd;
            border-radius: 5px;
            color: #fff;
            white-space: nowrap;
          }
        }

        &-wares {
          display: inline-block;
          width: calc(100% - 70px);
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-left: 10px;

          > p {
            margin: 0px;
          }
        }
      }

      .introduce-price {
        margin-top: 20px;
        padding: 25px 15px;
        background: #f5f5f5;

        &-text {
          padding: 10px 0px;
          display: flex;
          flex-direction: row;
          flex-flow: row;

          &:first-of-type {
            padding-top: 0px;
          }

          &:last-of-type {
            padding-bottom: 0px;
          }
        }

        .money {
          color: #e38022;
          font-size: 15px;

          &-integer {
            font-weight: bold;
            font-size: 19px;

            &:before {
              content: "￥";
            }
          }

          &-decimal {
            &:before {
              content: ".";
            }
          }
        }

        .full-reduction {
          color: #2a59fd;
          display: inline-block;

          &-item {
            display: inline-block;
            background: transparent;
            background-image: url("../../assets/details/full_reduction.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 2px 8px;
            margin-right: 12px;
            margin-bottom: 4px;
            cursor: pointer;
          }

          &-other {
            display: inline-block;
            margin-left: 5px;
            cursor: pointer;
          }
        }

        .sales {
          position: relative;

          &-item {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 7px;
            margin-right: 5px;

            &:first-of-type {
              margin-top: 0px;
            }

            &:last-of-type {
              margin-right: 0px;
            }

            cursor: pointer;

            &-icon {
              color: #1b50fe;
              display: inline-block;
              padding: 2px 8px;
              background: transparent;
              background-image: url("../../assets/details/sales.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            &-text {
              display: inline;
              color: #333333;
              margin-left: 12px;
            }
          }
        }
      }

      .introduce-mode {
        padding: 20px 15px 20px 15px;

        &-item {
          width: 100%;
          min-height: 30px;
          display: flex;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 7px;
          margin-right: 5px;

          &:first-of-type {
            margin-top: 0px;
          }

          &:last-of-type {
            margin-right: 0px;
          }
        }

        .delivery {
          width: 100%;
          display: flex;
          flex-direction: row;

          &-place {
            display: inline-block;
            width: 233px;
            height: 36px;
            cursor: pointer;

            &-item {
              overflow: hidden;
              padding: 3px 39px 3px 15px;
              background: transparent;
              background-image: url("../../assets/details/address.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              line-height: 30px;
            }
          }

          &-cost {
            display: inline-block;
            height: 36px;
            margin-left: 10px;
            cursor: pointer;

            &-item {
              padding: 3px 39px 3px 15px;
              background: transparent;
              background-image: url("../../assets/details/freight.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              line-height: 30px;
            }
          }

          &-other {
            display: inline-block;
            height: 36px;
            margin-left: 10px;
            color: #878787;
            line-height: 36px;
            cursor: pointer;
          }
        }

        .counter {
          display: flex;
          height: 33px;

          &-btn {
            display: inline-block;
            background: transparent;
            background-image: url("../../assets/details/counter_btn.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }

          &-number {
            display: inline-block;
            height: 100%;
            width: 55px;

            > input {
              display: inline-block;
              padding: 0px 6px;
              background: transparent;
              text-align: center;
              height: 98%;
              width: calc(100% - 9px);
              border: 0px;

              &:focus {
                outline: none;
              }
            }

            background-image: url("../../assets/details/counter_number.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          &-plus,
          &-reduce {
            height: 100%;
            width: 33px;
            text-align: center;
            line-height: 33px;
          }

          &-other {
            margin-left: 6px;
            line-height: 33px;
            color: #888888;
          }
        }
      }

      .button-cart,
      .button-order {
        background: transparent;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 35px;
        line-height: 35px;
        border: 0px;

        > span {
          font-size: 12px;
        }

        &:focus {
          outline: none;
        }

        cursor: pointer;
      }

      .button-order {
        color: #184fff;
        background-image: url("../../assets/details/place_an_order.png");
        width: 106px;
      }

      .button-cart {
        margin-left: 20px;
        color: #fff;
        background-image: url("../../assets/details/place_an_cart.png");
        width: 128px;
        padding-right: 10px;

        > span {
          margin-left: 28px;
        }
      }
    }

    &-look {
      margin-left: 36px;
      width: calc(100% - 345px - 637px);
    }
  }
}
</style>
